import * as React from "react";
import { Autocomplete, TextField } from "@mui/material";

export default function AutoComplete(props) {

    const { listData, onChange } = props;
    const keys = listData && listData.length > 0 ? Object.keys(listData[0]) : []

    return (
        <Autocomplete
            options={listData}
            getOptionLabel={(option) => option[keys[1]] || ""}
            onChange={(event, newValue) => {
                if (newValue) {
                    onChange({ [keys[0]]: newValue[keys[0]] })
                }
            }}
            renderInput={(params) => <TextField {...params} label={props.label} />}
            size="small"
        />
    );
}
