import * as React from 'react'
import { Typography } from '@mui/material'
export default function HeadingText(props) {

    return (
        <>
            <Typography
                sx={{
                    textAlign: 'center', fontSize: 30, fontWeight: 'bolder',
                    textShadow: 'grey 3px 3px 5px'
                }}>{props.text}</Typography>
        </>
    )
}