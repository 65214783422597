import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import '../../../services/globalVariables'
import axios from 'axios'

const API_URL = global.API_URL + 'sales/'

const initialState = {
    buyerNames: [],
    salesData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: ""
}

export const getBuyerNames = createAsyncThunk('sales/gbn', async (_, thunkAPI) => {
    try {
        const result = await axios.get(API_URL + 'getBuyerName')
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getBuyerWiseSalesData = createAsyncThunk('sales/gbwsd', async (mhCode, thunkAPI) => {
    try {
        const result = await axios.post(API_URL + 'getBuyerWiseSalesData', mhCode)
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const salesSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {
        reset: (state) => {
            state.buyerNames = []
            state.salesData = []
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getBuyerNames.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getBuyerNames.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.buyerNames = action.payload
            })
            .addCase(getBuyerNames.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.buyerNames = []
            })
            .addCase(getBuyerWiseSalesData.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getBuyerWiseSalesData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.salesData = action.payload
            })
            .addCase(getBuyerWiseSalesData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.salesData = []
            })
    }
})

export const { reset } = salesSlice.actions

export default salesSlice.reducer