import * as React from 'react'
import { Button, Container, Grid2, TextField, Typography, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../Setup/Navbar'
import { addNewUser, getAllUsers, deleteUser } from '../../features/security/authSlice'
import '../../services/globalVariables'
import ErrorMsg from '../../BuiltInComponent/ErrorMsg'
import TableWithPagination from '../../BuiltInComponent/TableWithPagination'

export default function AddNewUser() {

    const dispatch = useDispatch()

    const theme = useTheme()

    const headingStyle = {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bolder',
        backgroundColor: theme.palette.primary.main,
        padding: 5,
        borderRadius: 20,
        color: 'white',
        boxShadow: 'grey 2px 2px 5px',
    }

    const { allUsers } = useSelector(state => state.auth)

    const [userData, setUserData] = React.useState({
        dispName: ''
        , userName: ''
        , userPassword: ''
    })

    React.useEffect(() => {
        dispatch(getAllUsers())
    }, [dispatch])

    const handleOnChange = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        })
    }

    const addUser = () => {
        dispatch(addNewUser(userData)).then(() => {
            dispatch(getAllUsers());
            setUserData({ dispName: '', userName: '', userPassword: '' }); // Reset form
        });
    }

    const tableColumns = [
        { fieldName: 'userID', cName: 'User ID', width: 10, textAlign: 'left', status: 'en' },
        { fieldName: 'userName', cName: 'User Name', width: 10, textAlign: 'left', status: 'en' },
        { fieldName: 'dispName', cName: 'Display Name', width: 10, textAlign: 'left', status: 'en' },
        { fieldName: 'userStatus', cName: 'Status', width: 10, textAlign: 'left', status: 'en' },
        { fieldName: 'edit', cName: 'Edit', width: 10, textAlign: 'center', status: 'en' },
        { fieldName: 'delete', cName: 'Delete', width: 10, textAlign: 'center', status: 'en' },
    ]

    const onDeleteClick = (id) => {
        dispatch(deleteUser({ userID: id })).then(() => {
            dispatch(getAllUsers());
            setUserData({ dispName: '', userName: '', userPassword: '' });
        });

    }

    return (
        <>
            <Navbar />
            <Container maxWidth='xs' sx={{ padding: 2, borderRadius: 2, bgcolor: '#fcfcfc', boxShadow: 'grey 1px 1px 3px' }}>
                <Grid2 container spacing={1} >
                    <Grid2 size={12} sx={{ pl: 10, pr: 10, mt: 5, mb: 5 }}>
                        <Typography style={{ ...headingStyle }} >
                            Add New User
                        </Typography>
                    </Grid2>
                    <Grid2 size={12} >
                        <TextField
                            id="dispName"
                            label="Full Name"
                            variant="outlined"
                            name='dispName'
                            onChange={handleOnChange}
                            fullWidth />
                    </Grid2>
                    <Grid2 size={12} >
                        <TextField
                            id="userName"
                            label="User Name"
                            variant="outlined"
                            name='userName'
                            onChange={handleOnChange}
                            fullWidth />
                    </Grid2>
                    <Grid2 size={12} >
                        <TextField
                            id="userPassword"
                            label="User Password"
                            variant="outlined"
                            name='userPassword'
                            type='password'
                            onChange={handleOnChange}
                            fullWidth />
                    </Grid2>
                    <Grid2 size={12} sx={{ pl: 5, pr: 5 }}>
                        <Button
                            sx={{ mt: 2 }}
                            fontSize='20'
                            variant='contained'
                            fullWidth
                            onClick={addUser}
                        >
                            <Typography style={{ color: 'white', fontSize: 20, fontWeight: 'bolder' }}>
                                SAVE
                            </Typography>
                        </Button>
                    </Grid2>
                    <Grid2 size={12}>
                        {global.msgError !== '' ?
                            <ErrorMsg message={global.msgError} msgColor='red' /> : <></>}
                    </Grid2>
                </Grid2>
            </Container >
            <Container>
                <Grid2 Container spacing={2} sx={{ mt: 4 }}>
                    <Grid2 size={2}></Grid2>
                    <Grid2 size={6}>
                        <TableWithPagination tableColumns={tableColumns} tableData={allUsers} perPage={50} onDeleteClick={onDeleteClick} />
                    </Grid2>
                    <Grid2 size={2}></Grid2>
                </Grid2>

            </Container>
        </>
    )
}

