import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export default function BasicButtons(props) {

    return (
        <Stack spacing={2} direction="row">
            <Button
                sx={{ color: 'white', fontSize: 14, fontWeight: 'bolder' }}
                variant="contained"
                fullWidth
                onClick={() => {
                    const onClickFunc = props.onClick
                    onClickFunc()
                }}
            >
                {props.text}
            </Button>
        </Stack>
    );
}
