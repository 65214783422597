import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemButton, ListItemText, Divider, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function BasicMenu() {

    // const dispatch = useDispatch()
    const navigate = useNavigate()

    const { navbarMenu } = useSelector((state) => state.menus)

    return (
        <>
            {navbarMenu.map((hm, index) => (
                <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ minHeight: 32, '& .MuiAccordionSummary-content': { margin: 1 } }}
                    >
                        <Typography>{hm.headMenu}</Typography>
                    </AccordionSummary>
                    {hm.ArrSubMenu.map((sm, subIndex) => (
                        <AccordionDetails key={`${index}-${subIndex}`} sx={{ padding: '4px 8px' }}>
                            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <nav aria-label="main mailbox folders">
                                    <List sx={{ p: 0, m: 0 }}>
                                        <Divider sx={{ bgcolor: 'black' }} />
                                        <Divider sx={{ bgcolor: 'yellow' }} />
                                        <Divider sx={{ bgcolor: 'black' }} />
                                        <ListItem sx={{ p: 0, m: 0 }}>
                                            <ListItemButton sx={{ py: 0.5, px: 1 }} onClick={() => navigate(`${sm.menuURL}`)}>
                                                <ListItemText primaryTypographyProps={{ fontSize: 13 }} primary={`${sm.subMenu}`} />
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider sx={{ bgcolor: 'black' }} />
                                        <Divider sx={{ bgcolor: 'yellow' }} />
                                        <Divider sx={{ bgcolor: 'black' }} />
                                    </List>
                                </nav>
                            </Box>
                        </AccordionDetails>
                    ))}
                </Accordion>
            ))}
        </>
    );
}