import * as React from 'react';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

const settings = {
    width: 400,
    height: 400,
    value: 79,

};

export default function ArcDesign() {
    return (
        <Gauge
            {...settings}
            cornerRadius="50%"
            sx={(theme) => ({
                [`& .${gaugeClasses.valueText}`]: {
                    fontSize: 40,
                },
                [`& .${gaugeClasses.valueArc}`]: {
                    fill: '#52b202',
                },
                [`& .${gaugeClasses.referenceArc}`]: {
                    fill: theme.palette.text.disabled,
                },
            })}
            text={`${settings.value} / 100`}
        />
    );
}