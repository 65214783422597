import * as React from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';

export default function RowRadioButtonsGroup(props) {

    return (
        <FormControl sx={{ alignSelf: 'center' }}>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                alignSelf="center"
                value={props.selectedValue}
                onChange={(event) => {
                    const handleChangeRadioButtonFunc = props.handleChangeRadioButton
                    handleChangeRadioButtonFunc(event.target.value)
                }}
            >
                <FormControlLabel value="quantity" control={<Radio />} label="Quantity" />
                <FormControlLabel value="kg" control={<Radio />} label="KG" />
                <FormControlLabel value="lbs" control={<Radio />} label="LBS" />
            </RadioGroup>
        </FormControl>
    );
}
