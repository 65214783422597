import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import '../../services/globalVariables'
import axios from 'axios'

const API_URL = global.API_URL + 'setUserRight/'

const initialState = {
    allUsers: [],
    allMenus: [],
    userRights: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: ""
}

export const getAllUsers = createAsyncThunk('sur/gau', async (_, thunkAPI) => {
    try {
        const result = await axios.get(API_URL + 'getAllUsers')
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getAllMenus = createAsyncThunk('sur/gam', async (_, thunkAPI) => {
    try {
        const result = await axios.get(API_URL + 'getAllMenus')
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getUserRights = createAsyncThunk('sur/gur', async (userID, thunkAPI) => {
    try {
        const result = await axios.post(API_URL + 'getUserRights', userID)
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const updateUserRights = createAsyncThunk('sur/uur', async (userData, thunkAPI) => {
    try {
        const result = await axios.post(API_URL + 'updateUserRights', userData)
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const setUserRightsSlice = createSlice({
    name: 'menus',
    initialState,
    reducers: {
        reset: (state) => {
            state.allUsers = []
            state.allMenus = []
            state.userRights = []
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllUsers.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.allUsers = action.payload
            })
            .addCase(getAllUsers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.allUsers = []
            })
            .addCase(getAllMenus.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllMenus.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.allMenus = action.payload
            })
            .addCase(getAllMenus.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.allMenus = []
            })
            .addCase(getUserRights.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUserRights.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.userRights = action.payload
            })
            .addCase(getUserRights.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.userRights = []
            })
            .addCase(updateUserRights.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateUserRights.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.userRights = []
            })
            .addCase(updateUserRights.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.userRights = []
            })
    }
})

export const { reset } = setUserRightsSlice.actions

export default setUserRightsSlice.reducer