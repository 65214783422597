import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import '../../services/globalVariables'
import axios from 'axios'

const API_URL = global.API_URL + 'security/'

const initialState = {
    parentMenus: [],
    menuIDWise: [],
    navbarMenu: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: ""
}

export const addNewMenu = createAsyncThunk('sec/anm', async (menuData, thunkAPI) => {
    try {
        const result = await axios.post(API_URL + 'addNewMenu', menuData)
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getParentMenu = createAsyncThunk('sec/gpm', async (_, thunkAPI) => {
    try {
        const result = await axios.get(API_URL + 'getParentMenu')
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getMenusIDWise = createAsyncThunk('sec/gmiw', async (menuID, thunkAPI) => {
    try {

        const result = await axios.post(API_URL + 'getMenusIDWise', menuID)

        return result.data

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getMenusUserIDWise = createAsyncThunk('sec/gmuiw', async (userID, thunkAPI) => {
    try {

        const result = await axios.post(API_URL + 'getMenusUserIDWise', userID)

        return result.data

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const menuSlice = createSlice({
    name: 'menus',
    initialState,
    reducers: {
        reset: (state) => {
            state.parentMenus = []
            state.menuIDWise = []
            state.navbarMenu = []
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getParentMenu.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getParentMenu.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.parentMenus = action.payload
            })
            .addCase(getParentMenu.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.parentMenus = []
            })
            .addCase(getMenusIDWise.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMenusIDWise.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.menuIDWise = action.payload
            })
            .addCase(getMenusIDWise.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.menuIDWise = []
            })
            .addCase(getMenusUserIDWise.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMenusUserIDWise.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.navbarMenu = action.payload
            })
            .addCase(getMenusUserIDWise.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.navbarMenu = []
            })
    }
})

export const { reset } = menuSlice.actions

export default menuSlice.reducer