import * as React from 'react';
import TextField from '@mui/material/TextField';

export default function TextFields(props) {
    const { label, id, name, onChange, value } = props
    return (
        <TextField
            id={id}
            name={name}
            label={label}
            variant="outlined"
            size='small'
            fullWidth
            value={value}
            onChange={(e) => {
                onChange({ [name]: e.target.value })
            }}
        />
    );
}
