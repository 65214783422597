import { Typography } from '@mui/material'
import * as React from 'react'
import { useLocation } from 'react-router-dom'


export default function BuyerWiseStock() {
    const location = useLocation()
    const { itemCode } = location.state || {}
    return (
        <>
            <Typography sx={{ mt: 40 }}>
                {itemCode
                    ? itemCode
                    : 'Not Found'}
                Done
            </Typography>
        </>
    )
}
