import * as React from 'react'
import { Container, Grid2, Typography } from '@mui/material'
import Navbar from '../../compnents/Setup/Navbar'
import Autocomplete from '../../BuiltInComponent/AutoComplete'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsers, getAllMenus, getUserRights, updateUserRights } from '../../features/security/setUserRightsSlice'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import { useTheme } from '@emotion/react';
import Switch from '@mui/material/Switch';
import ButtonNormal from '../../BuiltInComponent/ButtonNormal'

export default function SetUserRights() {
    const dispatch = useDispatch()
    const { allUsers, userRights } = useSelector(state => state.sur)
    const [userRightsData, setUserRightsData] = React.useState([])
    const tableColumns = [
        { fieldName: 'menuID', cName: 'Menu ID', width: 10, textAlign: 'left', status: 'en' },
        { fieldName: 'headMenu', cName: 'Parent Menu', width: 10, textAlign: 'left', status: 'en' },
        { fieldName: 'subMenu', cName: 'Menu Name', width: 10, textAlign: 'left', status: 'en' },
        { fieldName: 'allowed', cName: 'Allow', width: 10, textAlign: 'center', status: 'en', swithButton: true },
        { fieldName: 'edit', cName: 'Edit', width: 10, textAlign: 'center', status: 'di' },
        { fieldName: 'delete', cName: 'Delete', width: 10, textAlign: 'center', status: 'di' },
    ]

    React.useEffect(() => {
        dispatch(getAllUsers()).then(() => {
            dispatch(getAllMenus())
        })
    }, [dispatch])

    const onChange = (data) => {
        const keys = Object.keys(data)
        // setMenuData(prevData => ({
        //     ...prevData, [keys]: data[keys]
        // }))
        console.log(data[keys])
        if (keys[0] === 'userID') {
            dispatch(getUserRights({ userID: data[keys] }))
                .then((response) => {
                    setUserRightsData(response.payload || userRights);
                })
        }
    }
    const onChangeSwitch = (updatedRow) => {
        setUserRightsData((prevData) =>
            prevData.map((row) =>
                row.subMenu === updatedRow.subMenu
                    ? { ...row, allowed: !row.allowed }
                    : row
            )
        )
    }
    const onClickUpdateUserRights = () => {
        dispatch(updateUserRights(userRightsData))
            .then((response) => {
                setUserRightsData(response.payload || []);
            })
    }
    return (
        <>
            <Navbar />
            <Container>
                <Typography>Set User Rights</Typography>
                <Grid2 container spacing={2}>
                    <Grid2 size={4}>
                        <Autocomplete
                            listData={allUsers && allUsers}
                            onChange={onChange}
                            label='Select User'
                        />
                    </Grid2>
                    <Grid2 size={4}></Grid2>
                    <Grid2 size={4}>
                        <ButtonNormal onClick={onClickUpdateUserRights} text='Update Rights' />
                    </Grid2>
                    <Grid2 size={12}>
                        <StickyHeadTable tableColumns={tableColumns} tableData={userRightsData || []} perPage={50} onChangeSwitch={onChangeSwitch} />
                    </Grid2>
                </Grid2>
            </Container>
        </>
    )
}




function StickyHeadTable(props) {

    const theme = useTheme()

    const perPage = props.perPage
    const tableColumns = props.tableColumns
    const rows = props.tableData
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(perPage || 5);
    const [filter, setFilter] = React.useState(''); // New state for filtering
    const [filteredRows, setFilteredRows] = React.useState(rows); // New state to hold filtered data

    // Handle pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Handle the filter input change
    const handleFilterChange = (event) => {
        const filterValue = event.target.value.toLowerCase();
        setFilter(filterValue);

        // Filter rows based on filter input
        const filteredData = rows.filter(row => {
            return tableColumns.some(col => {
                const cellValue = row[col.fieldName]?.toString().toLowerCase();
                return cellValue && cellValue.includes(filterValue);
            });
        });

        setFilteredRows(filteredData);
    };

    React.useEffect(() => {
        setFilteredRows(rows); // Update filteredRows if rows change
    }, [rows]);

    const tableHeadStyle = {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: `left`,
        fontWeight: 'bolder',
        width: `40%`,
        textShadow: 'black 2px 2px 4px'
    }
    const handleSwitchChange = (index) => {
        const updatedRows = [...rows];
        updatedRows[index] = {
            ...updatedRows[index],
            allowed: !updatedRows[index].allowed // Toggle the allowed key
        };
        props.onChangeSwitch(updatedRows[index]); // Notify the parent component of the change
        setFilteredRows(updatedRows);
        const onChangeSwitchFunc = props.onChangeSwitch
        onChangeSwitchFunc(filteredRows)
        // console.log(filteredRows)
    };
    return (
        <Paper sx={{ width: '98%', overflow: 'hidden', backgroundColor: 'rgb(250, 250, 250)' }}>
            <Grid2 container spacing={1}>
                <Grid2 size={4}></Grid2>
                <Grid2 size={4}>
                    <TextField
                        sx={{ margin: '20px' }}
                        label="Filter"
                        variant="outlined"
                        fullWidth
                        value={filter}
                        onChange={handleFilterChange}
                        placeholder="Search by any column..."
                        size='small'
                    />
                </Grid2>
                <Grid2 size={4}></Grid2>
            </Grid2>
            <TableContainer sx={{ maxHeight: 450 }}>
                <Table stickyHeader aria-label="sticky table" size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell key={Math.random()} style={tableHeadStyle}>Menu Head Description</TableCell>
                            <TableCell key={Math.random()} style={tableHeadStyle}>Menu Description</TableCell>
                            <TableCell key={Math.random()} style={tableHeadStyle}>Menu Allowed / Not</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows && filteredRows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, rowIndex) => {
                                return (
                                    <TableRow
                                        key={rowIndex}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        style={{ width: '70%', }}
                                    >
                                        <TableCell key={Math.random()} style={{ textAlign: `left` }} component="th" scope="row">
                                            {row.headMenu}
                                        </TableCell>
                                        <TableCell key={Math.random()} style={{ textAlign: `left` }} component="th" scope="row">
                                            {row.subMenu}
                                        </TableCell>
                                        <TableCell key={Math.random()} style={{ textAlign: `left` }} component="th" scope="row">
                                            <Switch checked={row.allowed} onChange={() => handleSwitchChange(rowIndex)} />
                                        </TableCell>

                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={filteredRows && filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
