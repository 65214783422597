import * as React from 'react'
import Navbar from '../Setup/Navbar'
import { BarChart } from '@mui/x-charts/BarChart';

export default function ProductionCharts(props) {
    const chartData = [
        {
            "Year": 24,
            "MonthNo": 1,
            "Month": "Jan",
            "prod": "66695"
        },
        {
            "Year": 24,
            "MonthNo": 2,
            "Month": "Feb",
            "prod": "57070"
        },
        {
            "Year": 24,
            "MonthNo": 3,
            "Month": "Mar",
            "prod": "67653"
        },
        {
            "Year": 24,
            "MonthNo": 4,
            "Month": "Apr",
            "prod": "58337"
        },
        {
            "Year": 24,
            "MonthNo": 5,
            "Month": "May",
            "prod": "73810"
        },
        {
            "Year": 24,
            "MonthNo": 6,
            "Month": "Jun",
            "prod": "64341"
        },
        {
            "Year": 24,
            "MonthNo": 7,
            "Month": "Jul",
            "prod": "66795"
        },
        {
            "Year": 24,
            "MonthNo": 8,
            "Month": "Aug",
            "prod": "76553"
        },
        {
            "Year": 24,
            "MonthNo": 9,
            "Month": "Sep",
            "prod": "81390"
        },
        {
            "Year": 24,
            "MonthNo": 10,
            "Month": "Oct",
            "prod": "91866"
        },
        {
            "Year": 24,
            "MonthNo": 11,
            "Month": "Nov",
            "prod": "80886"
        },
        {
            "Year": 24,
            "MonthNo": 12,
            "Month": "Dec",
            "prod": "11645"
        }
    ]

    return (
        <>
            <div style={{ height: 400, width: '100%' }}>
                <BarChart
                    xAxis={[
                        {
                            data: chartData.map((item) => item.Month),
                            scaleType: 'band',
                        },
                    ]}
                    series={[
                        {
                            data: chartData.map((item) => item.prod),
                            label: props.title,
                            color: props.barColor,
                            valueLabels: {
                                display: true, // Enable value labels
                                position: 'outside', // Show values above the bar
                                style: { fill: '#000', fontSize: 12, fontWeight: 'bold' },
                                label: 'done'
                            },
                        },
                    ]}
                    margin={{ top: 20, bottom: 40, left: 50, right: 20 }}
                />

            </div>
        </>
    )
}

