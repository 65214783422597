import * as React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Grid2 } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTheme } from '@emotion/react';
import SwtichButton from './SwitchButton'


export default function StickyHeadTable(props) {

    const theme = useTheme()

    const perPage = props.perPage
    const tableColumns = props.tableColumns
    const rows = props.tableData

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(perPage || 5);
    const [filter, setFilter] = React.useState(''); // New state for filtering
    const [filteredRows, setFilteredRows] = React.useState(rows); // New state to hold filtered data

    // Handle pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // Handle the filter input change
    const handleFilterChange = (event) => {
        const filterValue = event.target.value.toLowerCase();
        setFilter(filterValue);

        // Filter rows based on filter input
        const filteredData = rows.filter(row => {
            return tableColumns.some(col => {
                const cellValue = row[col.fieldName]?.toString().toLowerCase();
                return cellValue && cellValue.includes(filterValue);
            });
        });

        setFilteredRows(filteredData);
    };

    React.useEffect(() => {
        setFilteredRows(rows); // Update filteredRows if rows change
    }, [rows]);

    return (
        <Paper sx={{ width: '98%', overflow: 'hidden', backgroundColor: 'rgb(250, 250, 250)' }}>
            <Grid2 container spacing={1}>
                <Grid2 size={4}></Grid2>
                <Grid2 size={4}>
                    <TextField
                        sx={{ margin: '20px' }}
                        label="Filter"
                        variant="outlined"
                        fullWidth
                        value={filter}
                        onChange={handleFilterChange}
                        placeholder="Search by any column..."
                        size='small'
                    />
                </Grid2>
                <Grid2 size={4}></Grid2>
            </Grid2>
            <TableContainer sx={{ maxHeight: 450 }}>
                <Table stickyHeader aria-label="sticky table" size='small'>
                    <TableHead>
                        <TableRow>
                            {tableColumns && tableColumns.slice(1, tableColumns.length).map((columns, index) => (
                                tableColumns[index + 1].status === 'en' ?
                                    <>                                <TableCell
                                        key={index}
                                        style={{
                                            backgroundColor: theme.palette.primary.main,
                                            color: 'white',
                                            textAlign: `${tableColumns[index + 1].textAlign}`,
                                            fontWeight: 'bolder',
                                            width: `${columns.width}`,
                                            textShadow: 'black 2px 2px 4px'
                                        }}>
                                        {columns.cName}
                                    </TableCell>
                                    </> : <></>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows && filteredRows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, rowIndex) => {
                                return (
                                    <TableRow
                                        key={rowIndex}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        style={{ width: '70%', }}
                                    >
                                        {tableColumns.slice(1, tableColumns.length - 2).map((col, index) => (
                                            <TableCell
                                                key={index}
                                                style={{ textAlign: `${tableColumns[index + 1].textAlign}` }}
                                                component="th"
                                                scope="row"
                                                sx={{ border: 'ridge 1px' }}
                                            >
                                                {tableColumns[index + 1].dType === 'number'
                                                    ? `$${Math.round(row[tableColumns[index + 1].fieldName]).toLocaleString("en-US")}`
                                                    : tableColumns[index + 1].swithButton ?
                                                        <>
                                                            <SwtichButton status={row[tableColumns[index + 1].fieldName]}
                                                                funcOnChange={props.onChangeSwitch} />
                                                        </> : row[tableColumns[index + 1].fieldName]}
                                            </TableCell>
                                        ))}
                                        {tableColumns[tableColumns.length - 1].status === 'en' ?
                                            <TableCell style={{ textAlign: 'center', border: 'ridge 1px' }}>
                                                <EditIcon
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        const onEditClick = props.onEditClick
                                                        onEditClick(row[tableColumns[0].fieldName])
                                                    }}
                                                />
                                            </TableCell>
                                            :
                                            <></>
                                        }
                                        {tableColumns[tableColumns.length - 2].status === 'en' ?
                                            <TableCell style={{ textAlign: 'center', border: 'ridge 1px' }}>
                                                <DeleteForeverIcon
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        const deleteFunc = props.onDeleteClick
                                                        deleteFunc(row[tableColumns[0].fieldName])
                                                    }}
                                                />
                                            </TableCell>
                                            :
                                            <></>
                                        }
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={filteredRows && filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
