import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import '../../../services/globalVariables'
import axios from 'axios'

const API_URL = global.API_URL + 'production/'

const initialState = {
    sectionNames: [],
    mrgItemName: [],
    sectionData: [],
    navbarMenu: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: ""
}

export const getSectionNames = createAsyncThunk('pro/gsn', async (_, thunkAPI) => {
    try {
        const result = await axios.get(API_URL + 'getSectionName')
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getmrgItemName = createAsyncThunk('pro/gmin', async (_, thunkAPI) => {
    try {
        const result = await axios.get(API_URL + 'getmrgItemName')
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getSectionData = createAsyncThunk('pro/gsd', async (sectionCode, thunkAPI) => {
    try {
        const result = await axios.post(API_URL + 'getSectionData', sectionCode)
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getMergeItemData = createAsyncThunk('pro/gmid', async (mrgItemCode, thunkAPI) => {
    try {
        const result = await axios.post(API_URL + 'getMergeItemData', mrgItemCode)
        return result.data
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const productionSlice = createSlice({
    name: 'menus',
    initialState,
    reducers: {
        reset: (state) => {
            state.sectionNames = []
            state.mrgItemName = []
            state.sectionData = []
            state.navbarMenu = []
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ""
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getSectionNames.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSectionNames.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.sectionNames = action.payload
            })
            .addCase(getSectionNames.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.sectionNames = []
            })
            .addCase(getmrgItemName.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getmrgItemName.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.mrgItemName = action.payload
            })
            .addCase(getmrgItemName.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.mrgItemName = []
            })
            .addCase(getSectionData.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSectionData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.sectionData = action.payload
            })
            .addCase(getSectionData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.sectionData = []
            })
            .addCase(getMergeItemData.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMergeItemData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.sectionData = action.payload
            })
            .addCase(getMergeItemData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.sectionData = []
            })
    }
})

export const { reset } = productionSlice.actions

export default productionSlice.reducer