import * as React from 'react'
import { Button, Container, Grid2, TextField, Typography, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../features/security/authSlice'
import { useNavigate } from 'react-router-dom'
import '../../services/globalVariables'

import ErrorMsg from '../../BuiltInComponent/ErrorMsg'
export default function SignIn() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const theme = useTheme()

    const headingStyle = {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bolder',
        backgroundColor: theme.palette.primary.main,
        padding: 5,
        borderRadius: 20,
        color: 'white',
        boxShadow: 'grey 2px 2px 5px',
    }

    const { user } = useSelector(state => state.auth)

    const [userData, setUserData] = React.useState({
        userName: '',
        userPassword: ''
    })

    const handleOnChange = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        })
    }

    React.useEffect(() => {
        if (user) {
            if (!user.message) {
                navigate('/')
            }
        }
    }, [user, navigate])

    const loginUser = () => {
        dispatch(login(userData))
    }

    return (
        <Container maxWidth='xs' sx={{ mt: 20, padding: 2, borderRadius: 2, bgcolor: '#fcfcfc', boxShadow: 'grey 1px 1px 3px' }}>
            <Grid2 container spacing={1} >
                <Grid2 size={12} sx={{ pl: 10, pr: 10, mt: 5, mb: 5 }}>
                    <Typography style={{ ...headingStyle }} >
                        Sign In
                    </Typography>
                </Grid2>
                <Grid2 size={12} >
                    <TextField
                        id="userName"
                        label="User Name"
                        variant="outlined"
                        name='userName'
                        onChange={handleOnChange}
                        fullWidth />
                </Grid2>
                <Grid2 size={12} >
                    <TextField
                        id="userPassword"
                        label="User Password"
                        variant="outlined"
                        name='userPassword'
                        type='password'
                        onChange={handleOnChange}
                        fullWidth />
                </Grid2>
                <Grid2 size={12} sx={{ pl: 5, pr: 5 }}>
                    <Button
                        sx={{ mt: 2 }}
                        fontSize='20'
                        variant='contained'
                        fullWidth
                        onClick={loginUser}
                    >
                        <Typography style={{ color: 'white', fontSize: 20, fontWeight: 'bolder' }}>
                            LOGIN
                        </Typography>
                    </Button>
                </Grid2>
                <Grid2 size={12}>
                    {global.msgError !== '' ?
                        <ErrorMsg message={global.msgError} msgColor='red' /> : <></>}

                </Grid2>
            </Grid2>
        </Container >
    )
}

