import * as React from 'react';
import Switch from '@mui/material/Switch';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function BasicSwitches(props) {

    return (
        <div>
            <Switch
                {...label}
                checked={props.status}
                onChange={(event) => {
                    const funcOnChange = props.funcOnChange
                    funcOnChange(event.target.checked)
                }}
            />
        </div>
    );
}
